$primary-color: #3876ff;
$theme-color: #fff;
$page-bg-color: #f5f5f9;
$normal-color: rgba(57, 57, 70, 1);
$text-color: rgba(57, 57, 70, 0.9);
$lighter-color: rgba(57, 57, 70, 0.6);
$gray-color: #666;
$btn-text-color: #fff;

$primary-btn-color: linear-gradient(90deg, #69aeff 0%, #3876ff 100%);
$primary-btn-hover-color: linear-gradient(90deg, #87beff 0%, #6393fd 100%);
