@import "./var.scss";
:root {
  color-scheme: light dark;

  --background: #e3e3e3;
  --base-dark-color: rgba(0, 0, 0, 0.8);
  --base-light-color: #fff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #282828;
    --base-dark-color: #fff;
    --base-light-color: rgba(0, 0, 0, 0.8);
  }
}

body {
  background-color: var(--background);
  padding: 0px;
  width: 100vw;
  height: 100vh;

  // min-width: 768px;
  // min-height: 432px;
  overflow: hidden;
  user-select: none;
}

.title {
  padding-top: 10px;
  color: var(--base-dark-color);
}

.sub-title {
  color: var(--base-dark-color);
  margin-bottom: 20px;
}

.meeting-header {
  height: 32px;
  width: 100%;
  text-align: center;
  color: #e7e7e7;
  background-color: rgba(25, 29, 40, 1);
  z-index: 10;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-around;
}

.meeting-footer {
  width: 100%;
  height: 60px;
  color: #e7e7e7;
  background-color: rgba(25, 29, 40, 1);
  overflow: hidden;

  position: fixed;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  & > div {
    padding: 0 5px;
  }
}

.meeting-content {
  width: 100vw;
  height: calc(100vh - 92px);
  // height: 100vh;
  z-index: 10;

  position: fixed;
  top: 32px;
  left: 0;

  background-color: #282828;
  color: #e7e7e7;

  display: flex;
  align-items: center;
  justify-content: center;
}

.meeting-layout {
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #e7e7e7;
}

.has-error .ant-input,
.has-error .ant-input:hover {
  background-color: #282828;
  color: #e7e7e7;
}

.ant-row {
  z-index: 1000;
}

:global {
  .ant-form-item {
    margin-bottom: 5px;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  background: #000 url("./img/meeting_bg.png") 0 0 no-repeat;
  background-size: cover;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;

  overflow: hidden;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .avatar {
    width: 100px;
    height: 100px;
    border-radius: 100%;
    border: 2px solid #efefef;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .name {
    margin: 26px 0 48px;
    color: #fff;
    font-size: 14px;
  }

  .stop {
    width: 67px;
    height: 27px;
    cursor: pointer;

    transition: opacity ease 0.3s;
    &:hover {
      opacity: 0.8;
    }

    &:active {
      opacity: 1.2;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.hidden {
  img {
    display: none;
  }
}

.test {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: none;

  video {
    width: 164px;
    height: 146px;
  }
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: var(--base-light-color);
}

.ant-input {
  background-color: var(--base-light-color);
  color: var(--base-dark-color);
}

// 复写antd 样式
:global {
  .ant-btn {
    border-radius: 3px;
    border: 1px solid $primary-color;
    background: transparent;
    color: $primary-color;
    &:hover {
      background: #f8fafd;
    }
    &:active,
    &:focus {
      background: #eeeeee;
    }
  }
  .ant-btn-primary {
    color: #fff;
    background: $primary-btn-color;
    border: none;
    &:hover {
      background: $primary-btn-hover-color;
    }
    &:active,
    &:focus {
      background: $primary-btn-color;
      border: none;
    }
  }
  .ant-btn-link {
    border: 0;
    &:hover {
      background: transparent;
    }
    &:active,
    &:focus {
      background: transparent;
    }
  }
}
